@charset "UTF-8";
/**
 * @license
 * MyFonts Webfont Build ID 3614215, 2018-07-23T16:39:39-0400
 * 
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are 
 * explicitly restricted from using the Licensed Webfonts(s).
 * 
 * You may obtain a valid license at the URLs below.
 * 
 * Webfont: FrutigerLTPro-Light by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/frutiger/frutiger-pro-45-light/
 * 
 * Webfont: FrutigerLTPro-LightCn by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/frutiger/pro-47-light-condensed/
 * 
 * 
 * License: https://www.myfonts.com/viewlicense?type=web&buildid=3614215
 * Licensed pageviews: 15
 * Webfonts copyright: Copyright &#x00A9; 2014 - 2016 Monotype Imaging Inc. All rights reserved.
 * 
 * © 2018 MyFonts Inc
*/
@font-face {
  font-family: "FrutigerLTPro-Light";
  src: url("webfonts/372607_0_0.eot");
  src: url("webfonts/372607_0_0.eot?#iefix") format("embedded-opentype"), url("webfonts/372607_0_0.woff2") format("woff2"), url("webfonts/372607_0_0.woff") format("woff"), url("webfonts/372607_0_0.ttf") format("truetype"); }

@font-face {
  font-family: "FrutigerLTPro-LightCn";
  src: url("webfonts/372607_1_0.eot");
  src: url("webfonts/372607_1_0.eot?#iefix") format("embedded-opentype"), url("webfonts/372607_1_0.woff2") format("woff2"), url("webfonts/372607_1_0.woff") format("woff"), url("webfonts/372607_1_0.ttf") format("truetype"); }

body {
  font-family: "FrutigerLTPro-Light", sans-serif;
  font-size: 14px;
  color: #000;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }
  @media (min-width: 480px) {
    body {
      font-size: 16px; } }
  @media (min-width: 768px) {
    body {
      font-size: 18px; } }
  @media (min-width: 992px) {
    body {
      font-size: 20px; } }
  @media (min-width: 1280px) and (min-height: 700px) {
    body {
      font-size: 22px; } }
  @media (min-width: 1440px) and (min-height: 700px) {
    body {
      font-size: 24px; } }

h1 {
  font-weight: 300;
  font-size: 1.8em;
  line-height: 1.25em;
  margin: 1vw 0; }
  h1 strong {
    display: block;
    width: 40%;
    font-size: 2.4em;
    color: #90433b;
    line-height: 0.9em; }
    h1 strong.lead {
      margin: 0.5em 0; }

h2 {
  font-size: 1.125em;
  color: #000;
  font-weight: 300; }

p {
  font-size: 1em;
  color: #4d4d4d;
  line-height: 1.4em;
  display: block;
  margin: 0; }
  p.lead {
    width: auto;
    font-size: 1.2em;
    margin: 1em 0; }
  p.contact {
    width: auto;
    font-size: 1.6em;
    margin: -1em 0 1em; }
  p.legal {
    width: auto;
    font-size: 1em;
    margin: 0 0 0.75em;
    color: black;
    line-height: 1.4em;
    padding: 0; }
    @media (min-width: 480px) {
      p.legal {
        font-size: 0.6em; } }
    @media (min-width: 768px) {
      p.legal {
        font-size: 0.7em; } }
    @media (min-width: 992px) {
      p.legal {
        font-size: 0.8em; } }
    @media (min-width: 1280px) and (min-height: 700px) {
      p.legal {
        font-size: 0.9em; } }

a {
  font-size: 1.25em;
  color: #000;
  font-family: "FrutigerLTPro-LightCn", sans-serif; }
  a:hover {
    color: #000; }

small {
  max-width: calc(50% - 4vw - 6em);
  vertical-align: bottom; }
