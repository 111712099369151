// Mixins
//

$outerD: 48;
$innerD: 10;
$width: 542;
$height: 363;

@mixin picture($image) {
  .imgCenter {
    background: url(img/#{$image}-center.png) no-repeat center center;
    background-size: contain;
  }

  .imgLeft {
    background: url(img/#{$image}-left.png) no-repeat center center;
    background-size: contain;
  }

  .imgRight {
    background: url(img/#{$image}-right.png) no-repeat center center;
    background-size: contain;
  }

  .imgBackground {
    background: url(img/#{$image}-background.png) no-repeat center center;
    background-size: contain;
  }

  .imgBase {
    background: url(img/#{$image}-base.png) no-repeat center center;
    background-size: contain;
  }
}

@mixin hotSpotLeft($left, $top) {
  left: #{$left * 100 / $width + "%"};
  top: #{$top * 100 / $height + "%"};
  padding-top: #{$outerD * 100 / $width + "%"};
  padding-left: #{$outerD * 100 / $width + "%"};
  margin-top: #{"-" + ($outerD * 100 / $width) / 2 + "%"};
  margin-left: #{"-" + ($outerD * 100 / $width) / 2 + "%"};
}

@mixin popUpLeft($left, $top) {
  left: #{$left * 100 / $width + "%"};
  top: #{$top * 100 / $height + "%"};
  margin-left: 2em;
}

@mixin popUpLeftReversed($left, $top) {
  right: #{100 - ($left * 100 / $width) + "%"};
  left: auto;
  top: #{$top * 100 / $height + "%"};
  margin-left: 0;
  margin-right: 2em;
}

@mixin hotSpotRight($right, $top) {
  right: #{$right * 100 / $width + "%"};
  top: #{$top * 100 / $height + "%"};
  padding-top: #{$outerD * 100 / $width + "%"};
  padding-right: #{$outerD * 100 / $width + "%"};
  margin-top: #{"-" + ($outerD * 100 / $width) / 2 + "%"};
  margin-right: #{"-" + ($outerD * 100 / $width) / 2 + "%"};
}

@mixin popUpRight($right, $top) {
  right: #{$right * 100 / $width + "%"};
  top: #{$top * 100 / $height + "%"};
  margin-right: 2em;
}

@mixin popUpRightReversed($right, $top) {
  left: #{100 - ($right * 100 / $width) + "%"};
  right: auto;
  top: #{$top * 100 / $height + "%"};
  margin-right: 0;
  margin-left: 2em;
}
