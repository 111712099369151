@charset "UTF-8";
/**
 * @license
 * MyFonts Webfont Build ID 3614215, 2018-07-23T16:39:39-0400
 * 
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are 
 * explicitly restricted from using the Licensed Webfonts(s).
 * 
 * You may obtain a valid license at the URLs below.
 * 
 * Webfont: FrutigerLTPro-Light by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/frutiger/frutiger-pro-45-light/
 * 
 * Webfont: FrutigerLTPro-LightCn by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/frutiger/pro-47-light-condensed/
 * 
 * 
 * License: https://www.myfonts.com/viewlicense?type=web&buildid=3614215
 * Licensed pageviews: 15
 * Webfonts copyright: Copyright &#x00A9; 2014 - 2016 Monotype Imaging Inc. All rights reserved.
 * 
 * © 2018 MyFonts Inc
*/
@font-face {
  font-family: "FrutigerLTPro-Light";
  src: url("webfonts/372607_0_0.eot");
  src: url("webfonts/372607_0_0.eot?#iefix") format("embedded-opentype"), url("webfonts/372607_0_0.woff2") format("woff2"), url("webfonts/372607_0_0.woff") format("woff"), url("webfonts/372607_0_0.ttf") format("truetype"); }

@font-face {
  font-family: "FrutigerLTPro-LightCn";
  src: url("webfonts/372607_1_0.eot");
  src: url("webfonts/372607_1_0.eot?#iefix") format("embedded-opentype"), url("webfonts/372607_1_0.woff2") format("woff2"), url("webfonts/372607_1_0.woff") format("woff"), url("webfonts/372607_1_0.ttf") format("truetype"); }

.content {
  display: none; }
  @media (min-width: 480px) {
    .content {
      position: relative;
      display: flex;
      flex-direction: column;
      z-index: 100;
      width: auto;
      height: calc(100vh - 96px - 1vw - 42px - 2.5em); } }
  .content h1 {
    position: relative;
    z-index: 100;
    flex-basis: auto;
    flex-shrink: 1;
    flex-grow: 0;
    margin: 0 0 24px;
    opacity: 1;
    transition: all 1s ease-out; }
  .content .imgContainer {
    position: relative;
    display: inline-block;
    width: auto;
    flex-basis: auto;
    flex-shrink: 2;
    flex-grow: 1;
    z-index: 100;
    margin: 0; }
    .content .imgContainer .hotSpots {
      display: inline-block;
      position: relative;
      height: auto;
      width: auto; }
    .content .imgContainer .hotSpot {
      position: absolute;
      display: inline-block;
      z-index: 120;
      height: 0;
      width: 0;
      border-radius: 50%;
      transition: opacity 1s;
      opacity: 1;
      cursor: pointer; }
      .content .imgContainer .hotSpot::before {
        position: absolute;
        display: inline-block;
        top: 0;
        left: 0;
        content: "";
        height: 100%;
        width: 100%;
        background-color: #efe3cf;
        border-radius: 50%;
        z-index: 73;
        opacity: 0.55;
        overflow: visible; }
      .content .imgContainer .hotSpot::after {
        position: absolute;
        display: inline-block;
        top: 50%;
        left: 50%;
        margin: -10% 0 0 -10%;
        content: "";
        height: 20%;
        width: 20%;
        background-color: #dbaa35;
        border-radius: 50%;
        z-index: 75;
        overflow: visible; }
    .content .imgContainer .imgMask {
      position: absolute;
      top: 0;
      left: 0;
      display: inline-block;
      width: calc(100vw - 96px);
      height: 100%; }
    .content .imgContainer .imgCorners {
      position: absolute;
      left: -1%;
      top: -1%;
      width: 102%;
      height: 102%;
      z-index: 110;
      background: url(img/photo-corners.png) no-repeat center center;
      background-size: contain; }
    .content .imgContainer .imgCenter {
      position: absolute;
      left: 0;
      top: 0;
      display: inline-block;
      width: 100%;
      height: 100%;
      z-index: 50; }
    .content .imgContainer .imgLeft {
      position: absolute;
      left: 0;
      top: 0;
      display: inline-block;
      width: 100%;
      height: 100%;
      z-index: 30; }
    .content .imgContainer .imgRight {
      position: absolute;
      left: 0;
      top: 0;
      display: inline-block;
      width: 100%;
      height: 100%;
      z-index: 40; }
    .content .imgContainer .imgBackground {
      position: absolute;
      left: 0;
      top: 0;
      display: inline-block;
      width: 100%;
      height: 100%;
      z-index: 20; }
    .content .imgContainer .imgBase {
      position: absolute;
      left: 0;
      top: 0;
      display: inline-block;
      width: 100%;
      height: 100%;
      z-index: 10; }
    .content .imgContainer .imgShadow {
      position: absolute;
      left: 0;
      top: 0;
      display: inline-block;
      width: 100%;
      height: 100%;
      z-index: 1;
      background: url(img/shadow.png) no-repeat center center;
      background-size: contain;
      transform: scale(1.9); }
    .content .imgContainer .imgBase {
      display: inline-block;
      margin: 0 auto;
      max-height: 100%; }
  .content .caption {
    flex-basis: auto;
    flex-shrink: 1;
    flex-grow: 0;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: auto;
    padding: 1.25em 0.5em 0.75em; }
    @media (min-width: 768px) {
      .content .caption {
        padding: 2em 2em 4em; } }
    @media (min-width: 992px) {
      .content .caption {
        padding: 1.5em 1em 0.5em; } }
    @media (min-width: 1280px) and (min-height: 700px) {
      .content .caption {
        padding: 1.5em 2em 0.25em; } }

.enter .content {
  animation: fadein 2s ease-in; }
  .enter .content h1 {
    animation: slidein 2s ease-in; }
  .enter .content .imgContainer {
    animation: slidein 2s ease-in; }
    .enter .content .imgContainer .hotSpot {
      opacity: 0;
      animation: fadein 1s ease-in 2s forwards; }
      .enter .content .imgContainer .hotSpot::before {
        animation: pulse 1s ease-in-out 2s infinite;
        animation-direction: alternate;
        opacity: 0.55; }
      .enter .content .imgContainer .hotSpot::after {
        animation: pulseinner 1s ease-in-out 2s infinite;
        animation-direction: alternate;
        opacity: 1; }
    .enter .content .imgContainer .imgCenter {
      animation: fromtop 2s ease-in; }
    .enter .content .imgContainer .imgLeft {
      animation: fromleft 2s ease-in; }
    .enter .content .imgContainer .imgCorners {
      animation: fromabove 2s ease-in; }
    .enter .content .imgContainer .imgRight {
      animation: fromright 2s ease-in; }
    .enter .content .imgContainer .imgBackground {
      animation: frombottom 2s ease-in 0.25s; }
    .enter .content .imgContainer .imgShadow {
      animation: frombelow 2s ease-in 0.5s; }
  .enter .content p {
    animation: slidein 2s ease-in; }

.exit .content {
  animation: fadeout 2s ease-out forwards; }
  .exit .content h1 {
    animation: slideout 2s ease-out forwards; }
  .exit .content .imgContainer .imgCorners {
    animation: toabove 2s ease-out forwards; }
  .exit .content .imgContainer .imgCenter {
    animation: totop 2s ease-out forwards; }
  .exit .content .imgContainer .imgLeft {
    animation: toleft 2s ease-out forwards; }
  .exit .content .imgContainer .imgRight {
    animation: toright 2s ease-out forwards; }
  .exit .content .imgContainer .imgBackground {
    animation: tobottom 2s ease-out 0.25s forwards; }
  .exit .content .imgContainer .imgShadow {
    animation: tobelow 2s ease-out 0.5s forwards; }
  .exit .content p {
    animation: slideout 1s ease-out forwards; }

@keyframes pulse {
  from {
    transform: scale(1); }
  to {
    transform: scale(1.5); } }

@keyframes pulseinner {
  from {
    transform: scale(1); }
  to {
    transform: scale(1.5); } }

@keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeout {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes slidein {
  from {
    transform: translateY(-30px); }
  to {
    transform: translateY(0); } }

@keyframes slideout {
  from {
    transform: translateY(0); }
  to {
    transform: translateY(-30px); } }

@keyframes fromabove {
  from {
    transform: scale(2); }
  to {
    transform: scale(1); } }

@keyframes fromtop {
  from {
    transform: translateY(-20%); }
  to {
    transform: translateY(0); } }

@keyframes fromleft {
  from {
    transform: translateX(-20%); }
  to {
    transform: translateX(0); } }

@keyframes fromright {
  from {
    transform: translateX(20%); }
  to {
    transform: translateX(0); } }

@keyframes frombottom {
  from {
    transform: translateY(20%) scale(0.75); }
  to {
    transform: translateY(0) scale(1); } }

@keyframes frombelow {
  from {
    transform: scale(1); }
  to {
    transform: scale(1.9); } }

@keyframes toabove {
  from {
    transform: scale(1); }
  to {
    transform: scale(2); } }

@keyframes totop {
  from {
    transform: translateY(0); }
  to {
    transform: translateY(-20%); } }

@keyframes toleft {
  from {
    transform: translateX(0); }
  to {
    transform: translateX(-20%); } }

@keyframes toright {
  from {
    transform: translateX(0); }
  to {
    transform: translateX(20%); } }

@keyframes tobottom {
  from {
    transform: translateY(0) scale(1); }
  to {
    transform: translateY(20%) scale(0.75); } }

@keyframes tobelow {
  from {
    transform: scale(1.9); }
  to {
    transform: scale(1); } }

.theCapAndGown .imgContainer .popUpLeft {
  left: 20.1107%;
  top: 86.50138%;
  margin-left: 2em; }

.theCapAndGown .imgContainer .hotSpotLeft {
  left: 20.1107%;
  top: 86.50138%;
  padding-top: 8.85609%;
  padding-left: 8.85609%;
  margin-top: -4.42804%;
  margin-left: -4.42804%; }

.theCapAndGown .imgContainer .popUpRight {
  right: 14.76015%;
  top: 74.10468%;
  margin-right: 2em; }

.theCapAndGown .imgContainer .hotSpotRight {
  right: 14.76015%;
  top: 74.10468%;
  padding-top: 8.85609%;
  padding-right: 8.85609%;
  margin-top: -4.42804%;
  margin-right: -4.42804%; }

.theCapAndGown .imgContainer .imgCenter {
  background: url(img/1-center.png) no-repeat center center;
  background-size: contain; }

.theCapAndGown .imgContainer .imgLeft {
  background: url(img/1-left.png) no-repeat center center;
  background-size: contain; }

.theCapAndGown .imgContainer .imgRight {
  background: url(img/1-right.png) no-repeat center center;
  background-size: contain; }

.theCapAndGown .imgContainer .imgBackground {
  background: url(img/1-background.png) no-repeat center center;
  background-size: contain; }

.theCapAndGown .imgContainer .imgBase {
  background: url(img/1-base.png) no-repeat center center;
  background-size: contain; }

.theCapAndGown .imgContainer .imgCenter {
  z-index: 40; }

.theCapAndGown .imgContainer .imgRight {
  z-index: 50; }

.makingTheMove .imgContainer .popUpLeft {
  left: 15.68266%;
  top: 57.30028%;
  margin-left: 2em; }

.makingTheMove .imgContainer .hotSpotLeft {
  left: 15.68266%;
  top: 57.30028%;
  padding-top: 8.85609%;
  padding-left: 8.85609%;
  margin-top: -4.42804%;
  margin-left: -4.42804%; }

.makingTheMove .imgContainer .popUpRight {
  right: 18.81919%;
  top: 30.57851%;
  margin-right: 2em; }

.makingTheMove .imgContainer .hotSpotRight {
  right: 18.81919%;
  top: 30.57851%;
  padding-top: 8.85609%;
  padding-right: 8.85609%;
  margin-top: -4.42804%;
  margin-right: -4.42804%; }

.makingTheMove .imgContainer .imgCenter {
  background: url(img/2-center.png) no-repeat center center;
  background-size: contain; }

.makingTheMove .imgContainer .imgLeft {
  background: url(img/2-left.png) no-repeat center center;
  background-size: contain; }

.makingTheMove .imgContainer .imgRight {
  background: url(img/2-right.png) no-repeat center center;
  background-size: contain; }

.makingTheMove .imgContainer .imgBackground {
  background: url(img/2-background.png) no-repeat center center;
  background-size: contain; }

.makingTheMove .imgContainer .imgBase {
  background: url(img/2-base.png) no-repeat center center;
  background-size: contain; }

.onTheJob .imgContainer .popUpLeft {
  left: 15.68266%;
  top: 57.57576%;
  margin-left: 2em; }

.onTheJob .imgContainer .hotSpotLeft {
  left: 15.68266%;
  top: 57.57576%;
  padding-top: 8.85609%;
  padding-left: 8.85609%;
  margin-top: -4.42804%;
  margin-left: -4.42804%; }

.onTheJob .imgContainer .popUpRight {
  right: 19.5572%;
  top: 41.87328%;
  margin-right: 2em; }

.onTheJob .imgContainer .hotSpotRight {
  right: 19.5572%;
  top: 41.87328%;
  padding-top: 8.85609%;
  padding-right: 8.85609%;
  margin-top: -4.42804%;
  margin-right: -4.42804%; }

.onTheJob .imgContainer .imgCenter {
  background: url(img/3-center.png) no-repeat center center;
  background-size: contain; }

.onTheJob .imgContainer .imgLeft {
  background: url(img/3-left.png) no-repeat center center;
  background-size: contain; }

.onTheJob .imgContainer .imgRight {
  background: url(img/3-right.png) no-repeat center center;
  background-size: contain; }

.onTheJob .imgContainer .imgBackground {
  background: url(img/3-background.png) no-repeat center center;
  background-size: contain; }

.onTheJob .imgContainer .imgBase {
  background: url(img/3-base.png) no-repeat center center;
  background-size: contain; }

.expectTheUnexpected .imgContainer .popUpLeft {
  left: 26.38376%;
  top: 42.69972%;
  margin-left: 2em; }

.expectTheUnexpected .imgContainer .hotSpotLeft {
  left: 26.38376%;
  top: 42.69972%;
  padding-top: 8.85609%;
  padding-left: 8.85609%;
  margin-top: -4.42804%;
  margin-left: -4.42804%; }

.expectTheUnexpected .imgContainer .popUpRight {
  right: 16.60517%;
  top: 41.32231%;
  margin-right: 2em; }

.expectTheUnexpected .imgContainer .hotSpotRight {
  right: 16.60517%;
  top: 41.32231%;
  padding-top: 8.85609%;
  padding-right: 8.85609%;
  margin-top: -4.42804%;
  margin-right: -4.42804%; }

.expectTheUnexpected .imgContainer .imgCenter {
  background: url(img/4-center.png) no-repeat center center;
  background-size: contain; }

.expectTheUnexpected .imgContainer .imgLeft {
  background: url(img/4-left.png) no-repeat center center;
  background-size: contain; }

.expectTheUnexpected .imgContainer .imgRight {
  background: url(img/4-right.png) no-repeat center center;
  background-size: contain; }

.expectTheUnexpected .imgContainer .imgBackground {
  background: url(img/4-background.png) no-repeat center center;
  background-size: contain; }

.expectTheUnexpected .imgContainer .imgBase {
  background: url(img/4-base.png) no-repeat center center;
  background-size: contain; }

.expectTheUnexpected .imgContainer .imgCenter {
  z-index: 30; }

.expectTheUnexpected .imgContainer .imgLeft {
  z-index: 40; }

.expectTheUnexpected .imgContainer .imgRight {
  z-index: 50; }

.downTheAisle .imgContainer .popUpLeft {
  left: 31.18081%;
  top: 53.71901%;
  margin-left: 2em; }

.downTheAisle .imgContainer .hotSpotLeft {
  left: 31.18081%;
  top: 53.71901%;
  padding-top: 8.85609%;
  padding-left: 8.85609%;
  margin-top: -4.42804%;
  margin-left: -4.42804%; }

.downTheAisle .imgContainer .popUpRight {
  right: 23.43173%;
  top: 55.09642%;
  margin-right: 2em; }

.downTheAisle .imgContainer .hotSpotRight {
  right: 23.43173%;
  top: 55.09642%;
  padding-top: 8.85609%;
  padding-right: 8.85609%;
  margin-top: -4.42804%;
  margin-right: -4.42804%; }

.downTheAisle .imgContainer .imgCenter {
  background: url(img/5-center.png) no-repeat center center;
  background-size: contain; }

.downTheAisle .imgContainer .imgLeft {
  background: url(img/5-left.png) no-repeat center center;
  background-size: contain; }

.downTheAisle .imgContainer .imgRight {
  background: url(img/5-right.png) no-repeat center center;
  background-size: contain; }

.downTheAisle .imgContainer .imgBackground {
  background: url(img/5-background.png) no-repeat center center;
  background-size: contain; }

.downTheAisle .imgContainer .imgBase {
  background: url(img/5-base.png) no-repeat center center;
  background-size: contain; }

.homeSweetHome .imgContainer .popUpLeft {
  left: 7.19557%;
  top: 45.73003%;
  margin-left: 2em; }

.homeSweetHome .imgContainer .hotSpotLeft {
  left: 7.19557%;
  top: 45.73003%;
  padding-top: 8.85609%;
  padding-left: 8.85609%;
  margin-top: -4.42804%;
  margin-left: -4.42804%; }

.homeSweetHome .imgContainer .popUpRight {
  left: 41.32841%;
  right: auto;
  top: 49.03581%;
  margin-right: 0;
  margin-left: 2em; }
  .homeSweetHome .imgContainer .popUpRight.popUpRight .inner::before {
    right: auto;
    left: -2em;
    border-left: 0;
    border-right: 1em solid #000; }
  .homeSweetHome .imgContainer .popUpRight.popUpRight .inner::after {
    right: auto;
    left: calc(-2em + 2px);
    border-left: 0;
    border-right: calc(1em - 2px) solid #fff; }

.homeSweetHome .imgContainer .hotSpotRight {
  right: 58.67159%;
  top: 49.03581%;
  padding-top: 8.85609%;
  padding-right: 8.85609%;
  margin-top: -4.42804%;
  margin-right: -4.42804%; }

.homeSweetHome .imgContainer .imgCenter {
  background: url(img/6-center.png) no-repeat center center;
  background-size: contain; }

.homeSweetHome .imgContainer .imgLeft {
  background: url(img/6-left.png) no-repeat center center;
  background-size: contain; }

.homeSweetHome .imgContainer .imgRight {
  background: url(img/6-right.png) no-repeat center center;
  background-size: contain; }

.homeSweetHome .imgContainer .imgBackground {
  background: url(img/6-background.png) no-repeat center center;
  background-size: contain; }

.homeSweetHome .imgContainer .imgBase {
  background: url(img/6-base.png) no-repeat center center;
  background-size: contain; }

.homeSweetHome .imgContainer .imgCenter {
  z-index: 30; }

.homeSweetHome .imgContainer .imgLeft {
  z-index: 40; }

.homeSweetHome .imgContainer .imgRight {
  z-index: 50; }

.lifeAfterWork .imgContainer .popUpLeft {
  left: 42.43542%;
  top: 66.1157%;
  margin-left: 2em; }

.lifeAfterWork .imgContainer .hotSpotLeft {
  left: 42.43542%;
  top: 66.1157%;
  padding-top: 8.85609%;
  padding-left: 8.85609%;
  margin-top: -4.42804%;
  margin-left: -4.42804%; }

.lifeAfterWork .imgContainer .popUpRight {
  right: 17.52768%;
  top: 47.93388%;
  margin-right: 2em; }

.lifeAfterWork .imgContainer .hotSpotRight {
  right: 17.52768%;
  top: 47.93388%;
  padding-top: 8.85609%;
  padding-right: 8.85609%;
  margin-top: -4.42804%;
  margin-right: -4.42804%; }

.lifeAfterWork .imgContainer .imgCenter {
  background: url(img/7-center.png) no-repeat center center;
  background-size: contain; }

.lifeAfterWork .imgContainer .imgLeft {
  background: url(img/7-left.png) no-repeat center center;
  background-size: contain; }

.lifeAfterWork .imgContainer .imgRight {
  background: url(img/7-right.png) no-repeat center center;
  background-size: contain; }

.lifeAfterWork .imgContainer .imgBackground {
  background: url(img/7-background.png) no-repeat center center;
  background-size: contain; }

.lifeAfterWork .imgContainer .imgBase {
  background: url(img/7-base.png) no-repeat center center;
  background-size: contain; }

.bundleOfJoy .imgContainer .popUpLeft {
  right: 41.32841%;
  left: auto;
  top: 58.95317%;
  margin-left: 0;
  margin-right: 2em; }
  .bundleOfJoy .imgContainer .popUpLeft.popUpLeft .inner::before {
    left: auto;
    right: -2em;
    border-right: 0;
    border-left: 1em solid #000; }
  .bundleOfJoy .imgContainer .popUpLeft.popUpLeft .inner::after {
    right: calc(-2em + 2px);
    left: auto;
    border-right: 0;
    border-left: calc(1em - 2px) solid #fff; }

.bundleOfJoy .imgContainer .hotSpotLeft {
  left: 58.67159%;
  top: 58.95317%;
  padding-top: 8.85609%;
  padding-left: 8.85609%;
  margin-top: -4.42804%;
  margin-left: -4.42804%; }

.bundleOfJoy .imgContainer .popUpRight {
  right: 16.60517%;
  top: 60.60606%;
  margin-right: 2em; }

.bundleOfJoy .imgContainer .hotSpotRight {
  right: 16.60517%;
  top: 60.60606%;
  padding-top: 8.85609%;
  padding-right: 8.85609%;
  margin-top: -4.42804%;
  margin-right: -4.42804%; }

.bundleOfJoy .imgContainer .imgCenter {
  background: url(img/8-center.png) no-repeat center center;
  background-size: contain; }

.bundleOfJoy .imgContainer .imgLeft {
  background: url(img/8-left.png) no-repeat center center;
  background-size: contain; }

.bundleOfJoy .imgContainer .imgRight {
  background: url(img/8-right.png) no-repeat center center;
  background-size: contain; }

.bundleOfJoy .imgContainer .imgBackground {
  background: url(img/8-background.png) no-repeat center center;
  background-size: contain; }

.bundleOfJoy .imgContainer .imgBase {
  background: url(img/8-base.png) no-repeat center center;
  background-size: contain; }

.popUp {
  opacity: 0;
  display: inline-block;
  position: absolute;
  max-width: 320px;
  border-radius: 0.5em;
  background-color: #fff;
  border: 1px solid #000;
  padding: 1em;
  z-index: 2000;
  transform: scale(0);
  transform-origin: top center;
  min-width: 220px; }
  .popUp.popUpLeft {
    top: 50%;
    left: 0;
    transform: scale(0) translateY(-50%); }
    .popUp.popUpLeft .inner::before {
      left: -2em;
      border-top: 1em solid transparent;
      border-bottom: 1em solid transparent;
      border-right: 1em solid #000; }
    .popUp.popUpLeft .inner::after {
      left: calc(-2em + 2px);
      border-top: calc(1em - 2px) solid transparent;
      border-bottom: calc(1em - 2px) solid transparent;
      border-right: calc(1em - 2px) solid #fff; }
  .popUp.popUpRight {
    top: 50%;
    right: 0;
    transform: scale(0) translateY(-50%); }
    .popUp.popUpRight .inner::before {
      right: -2em;
      border-top: 1em solid transparent;
      border-bottom: 1em solid transparent;
      border-left: 1em solid #000; }
    .popUp.popUpRight .inner::after {
      right: calc(-2em + 2px);
      border-top: calc(1em - 2px) solid transparent;
      border-bottom: calc(1em - 2px) solid transparent;
      border-left: calc(1em - 2px) solid #fff; }
  .popUp.popUpLeft.isOpen {
    display: inline-block;
    opacity: 1;
    animation: openLeft 0.25s ease-out;
    transform: scale(1) translateY(-50%); }
  .popUp.popUpRight.isOpen {
    display: inline-block;
    opacity: 1;
    animation: openRight 0.25s ease-out;
    transform: scale(1) translateY(-50%); }
  .popUp .inner {
    display: inline-block;
    position: relative;
    width: 100%;
    height: 100%; }
    .popUp .inner::before {
      display: inline-block;
      content: "";
      position: absolute;
      top: calc(50% - 1em);
      left: auto;
      right: auto;
      width: 0;
      height: 0; }
    .popUp .inner::after {
      display: inline-block;
      content: "";
      position: absolute;
      top: calc(50% - 1em + 2px);
      left: auto;
      right: auto;
      width: 0;
      height: 0; }
  .popUp h2 {
    margin: 0;
    padding: 0; }
  .popUp hr {
    display: block;
    width: 80%;
    margin: 1em auto;
    padding: 0;
    height: 1px;
    background-color: #4d4d4d;
    border: 0; }
  .popUp p {
    font-size: 0.85em;
    color: #000;
    line-height: 1.25em;
    width: 100%;
    transition: none 0s !important;
    margin: 0;
    padding: 0; }

small {
  position: fixed;
  bottom: 3vw;
  left: 3vw;
  display: inline-block;
  opacity: 0;
  transition: all 0.25s ease-out;
  font-size: 0.7em; }
  small.isOpen {
    opacity: 1; }

@keyframes openLeft {
  from {
    opacity: 0.5;
    transform: scale(0.5) translateY(-50%); }
  to {
    opacity: 1;
    transform: scale(1) translateY(-50%); } }

@keyframes openRight {
  from {
    opacity: 0.5;
    transform: scale(0.5) translateY(-50%); }
  to {
    opacity: 1;
    transform: scale(1) translateY(-50%); } }
