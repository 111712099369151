@charset "UTF-8";
/**
 * @license
 * MyFonts Webfont Build ID 3614215, 2018-07-23T16:39:39-0400
 * 
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are 
 * explicitly restricted from using the Licensed Webfonts(s).
 * 
 * You may obtain a valid license at the URLs below.
 * 
 * Webfont: FrutigerLTPro-Light by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/frutiger/frutiger-pro-45-light/
 * 
 * Webfont: FrutigerLTPro-LightCn by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/frutiger/pro-47-light-condensed/
 * 
 * 
 * License: https://www.myfonts.com/viewlicense?type=web&buildid=3614215
 * Licensed pageviews: 15
 * Webfonts copyright: Copyright &#x00A9; 2014 - 2016 Monotype Imaging Inc. All rights reserved.
 * 
 * © 2018 MyFonts Inc
*/
@font-face {
  font-family: "FrutigerLTPro-Light";
  src: url("webfonts/372607_0_0.eot");
  src: url("webfonts/372607_0_0.eot?#iefix") format("embedded-opentype"), url("webfonts/372607_0_0.woff2") format("woff2"), url("webfonts/372607_0_0.woff") format("woff"), url("webfonts/372607_0_0.ttf") format("truetype"); }

@font-face {
  font-family: "FrutigerLTPro-LightCn";
  src: url("webfonts/372607_1_0.eot");
  src: url("webfonts/372607_1_0.eot?#iefix") format("embedded-opentype"), url("webfonts/372607_1_0.woff2") format("woff2"), url("webfonts/372607_1_0.woff") format("woff"), url("webfonts/372607_1_0.ttf") format("truetype"); }

.content {
  display: none; }
  @media (min-width: 480px) {
    .content {
      position: relative;
      height: calc(100vh - 96px - 1vw - 42px - 1.5em);
      display: flex;
      z-index: 100; } }
  .content .left {
    display: inline-block;
    width: 40%;
    float: left;
    height: calc(100vh - 96px - 1vw - 42px - 1.5em);
    display: flex;
    flex-direction: column;
    justify-content: center; }
    .content .left h1 {
      z-index: 100;
      position: relative;
      z-index: 110;
      margin: 0;
      flex-basis: auto;
      flex-shrink: 1;
      flex-grow: 0; }
      .content .left h1 strong {
        width: auto;
        margin: 0; }
    .content .left p {
      flex-basis: auto;
      flex-shrink: 3;
      flex-grow: 0;
      position: relative;
      z-index: 110;
      margin: 0.8em 0 0; }
  .content .right {
    display: inline-block;
    width: 60%;
    float: left;
    height: calc(100vh - 96px - 1vw - 42px - 1.5em);
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative; }
    .content .right .book {
      z-index: 10;
      position: relative;
      width: 100%;
      height: 100%;
      display: inline-block;
      background: url(img/00-album.png) no-repeat center center;
      background-size: contain; }
    .content .right .photos {
      z-index: 90;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: inline-block; }
      .content .right .photos .photo {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: inline-block;
        transform: translateY(-90px) translateX(20px) scale(1.5) rotate(15deg);
        opacity: 0; }
      .content .right .photos .photo1 {
        background: url(img/00-photo1.png) no-repeat center center;
        background-size: contain; }
      .content .right .photos .photo2 {
        background: url(img/00-photo2.png) no-repeat center center;
        background-size: contain; }
      .content .right .photos .photo3 {
        background: url(img/00-photo3.png) no-repeat center center;
        background-size: contain; }
      .content .right .photos .photo4 {
        background: url(img/00-photo4.png) no-repeat center center;
        background-size: contain; }
      .content .right .photos .photo5 {
        background: url(img/00-photo5.png) no-repeat center center;
        background-size: contain; }
      .content .right .photos .photo6 {
        background: url(img/00-photo6.png) no-repeat center center;
        background-size: contain; }
      .content .right .photos .photo7 {
        background: url(img/00-photo7.png) no-repeat center center;
        background-size: contain; }
      .content .right .photos .photo8 {
        background: url(img/00-photo8.png) no-repeat center center;
        background-size: contain; }

.enter .content {
  animation: fadein 3s ease-in; }
  .enter .content .left h1 {
    animation: slidein 2s ease-in; }
  .enter .content .left p {
    animation: slidein 2s ease-in; }
  .enter .content .right .book {
    z-index: 10;
    animation: dropin 0.75s ease-in forwards; }
  .enter .content .right .photos .photo:nth-child(8n + 1) {
    z-index: 20;
    animation: dropin 0.75s ease-in 1s forwards; }
  .enter .content .right .photos .photo:nth-child(8n + 2) {
    z-index: 30;
    animation: dropin 0.75s ease-in 1.25s forwards; }
  .enter .content .right .photos .photo:nth-child(8n + 3) {
    z-index: 40;
    animation: dropin 0.75s ease-in 1.5s forwards; }
  .enter .content .right .photos .photo:nth-child(8n + 4) {
    z-index: 50;
    animation: dropin 0.75s ease-in 1.75s forwards; }
  .enter .content .right .photos .photo:nth-child(8n + 5) {
    z-index: 60;
    animation: dropin 0.75s ease-in 2s forwards; }
  .enter .content .right .photos .photo:nth-child(8n + 6) {
    z-index: 70;
    animation: dropin 0.75s ease-in 2.25s forwards; }
  .enter .content .right .photos .photo:nth-child(8n + 7) {
    z-index: 80;
    animation: dropin 0.75s ease-in 2.5s forwards; }
  .enter .content .right .photos .photo:nth-child(8n + 8) {
    z-index: 90;
    animation: dropin 0.75s ease-in 2.75s forwards; }

.exit .content {
  animation: fadeout 3s ease-out forwards; }
  .exit .content .left h1 {
    animation: slideout 2s ease-out forwards; }
  .exit .content .left p {
    animation: slideout 2s ease-out forwards; }
  .exit .content .right .book {
    z-index: 10;
    animation: dropout 0.75s ease-out forwards; }
  .exit .content .right .photos .photo {
    opacity: 1;
    transform: translateY(0) translateX(0) scale(1) rotate(0deg); }
  .exit .content .right .photos .photo:nth-child(8n + 1) {
    z-index: 20;
    animation: dropout 0.75s ease-out 2.25s forwards; }
  .exit .content .right .photos .photo:nth-child(8n + 2) {
    z-index: 30;
    animation: dropout 0.75s ease-out 2s forwards; }
  .exit .content .right .photos .photo:nth-child(8n + 3) {
    z-index: 40;
    animation: dropout 0.75s ease-out 1.75s forwards; }
  .exit .content .right .photos .photo:nth-child(8n + 4) {
    z-index: 50;
    animation: dropout 0.75s ease-out 1.5s forwards; }
  .exit .content .right .photos .photo:nth-child(8n + 5) {
    z-index: 60;
    animation: dropout 0.75s ease-out 1.25s forwards; }
  .exit .content .right .photos .photo:nth-child(8n + 6) {
    z-index: 70;
    animation: dropout 0.75s ease-out 1s forwards; }
  .exit .content .right .photos .photo:nth-child(8n + 7) {
    z-index: 80;
    animation: dropout 0.75s ease-out 0.75s forwards; }
  .exit .content .right .photos .photo:nth-child(8n + 8) {
    z-index: 90;
    animation: dropout 0.75s ease-out 0.5s forwards; }

@keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeout {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes slidein {
  from {
    transform: translateY(-30px); }
  to {
    transform: translateY(0); } }

@keyframes slideout {
  from {
    transform: translateY(0); }
  to {
    transform: translateY(-30px); } }

@keyframes dropin {
  0% {
    opacity: 0;
    transform: translateY(-90px) translateX(20px) scale(1.5) rotate(15deg); }
  30% {
    opacity: 1; }
  100% {
    opacity: 1;
    transform: translateY(0) translateX(0) scale(1) rotate(0deg); } }

@keyframes dropout {
  0% {
    opacity: 1;
    transform: translateY(0) translateX(0) scale(1) rotate(0deg); }
  70% {
    opacity: 1; }
  100% {
    opacity: 0;
    transform: translateY(-90px) translateX(20px) scale(1.5) rotate(15deg); } }
