@charset "UTF-8";
/**
 * @license
 * MyFonts Webfont Build ID 3614215, 2018-07-23T16:39:39-0400
 * 
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are 
 * explicitly restricted from using the Licensed Webfonts(s).
 * 
 * You may obtain a valid license at the URLs below.
 * 
 * Webfont: FrutigerLTPro-Light by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/frutiger/frutiger-pro-45-light/
 * 
 * Webfont: FrutigerLTPro-LightCn by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/frutiger/pro-47-light-condensed/
 * 
 * 
 * License: https://www.myfonts.com/viewlicense?type=web&buildid=3614215
 * Licensed pageviews: 15
 * Webfonts copyright: Copyright &#x00A9; 2014 - 2016 Monotype Imaging Inc. All rights reserved.
 * 
 * © 2018 MyFonts Inc
*/
@font-face {
  font-family: "FrutigerLTPro-Light";
  src: url("webfonts/372607_0_0.eot");
  src: url("webfonts/372607_0_0.eot?#iefix") format("embedded-opentype"), url("webfonts/372607_0_0.woff2") format("woff2"), url("webfonts/372607_0_0.woff") format("woff"), url("webfonts/372607_0_0.ttf") format("truetype"); }

@font-face {
  font-family: "FrutigerLTPro-LightCn";
  src: url("webfonts/372607_1_0.eot");
  src: url("webfonts/372607_1_0.eot?#iefix") format("embedded-opentype"), url("webfonts/372607_1_0.woff2") format("woff2"), url("webfonts/372607_1_0.woff") format("woff"), url("webfonts/372607_1_0.ttf") format("truetype"); }

body {
  background: #ededed;
  height: 100vh;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none; }
  body::-webkit-scrollbar {
    width: 0 !important; }

.wrapper {
  position: fixed;
  width: calc(100vw - 48px);
  height: calc(100vh - 48px);
  border: 24px solid #ededed;
  background: #fff;
  z-index: 100; }

.header {
  display: block;
  position: relative;
  z-index: 500;
  margin: 0 0 24px; }

.container {
  width: calc(100vw - 96px);
  padding: 24px;
  height: calc(100vh - 96px);
  overflow: hidden; }

@media (min-width: 480px) {
  .message {
    display: none; } }

.message img {
  width: 100%; }
