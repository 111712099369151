@charset "UTF-8";
/**
 * @license
 * MyFonts Webfont Build ID 3614215, 2018-07-23T16:39:39-0400
 * 
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are 
 * explicitly restricted from using the Licensed Webfonts(s).
 * 
 * You may obtain a valid license at the URLs below.
 * 
 * Webfont: FrutigerLTPro-Light by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/frutiger/frutiger-pro-45-light/
 * 
 * Webfont: FrutigerLTPro-LightCn by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/frutiger/pro-47-light-condensed/
 * 
 * 
 * License: https://www.myfonts.com/viewlicense?type=web&buildid=3614215
 * Licensed pageviews: 15
 * Webfonts copyright: Copyright &#x00A9; 2014 - 2016 Monotype Imaging Inc. All rights reserved.
 * 
 * © 2018 MyFonts Inc
*/
@font-face {
  font-family: "FrutigerLTPro-Light";
  src: url("webfonts/372607_0_0.eot");
  src: url("webfonts/372607_0_0.eot?#iefix") format("embedded-opentype"), url("webfonts/372607_0_0.woff2") format("woff2"), url("webfonts/372607_0_0.woff") format("woff"), url("webfonts/372607_0_0.ttf") format("truetype"); }

@font-face {
  font-family: "FrutigerLTPro-LightCn";
  src: url("webfonts/372607_1_0.eot");
  src: url("webfonts/372607_1_0.eot?#iefix") format("embedded-opentype"), url("webfonts/372607_1_0.woff2") format("woff2"), url("webfonts/372607_1_0.woff") format("woff"), url("webfonts/372607_1_0.ttf") format("truetype"); }

.logo {
  display: inline-block;
  width: 114px;
  height: 42px;
  background: url("img/sprite-v6.png") no-repeat 0px 0px;
  background: url("img/sprite-v6.svg") no-repeat 0px 0px, none;
  text-indent: -99999px; }

.close {
  float: right;
  display: inline-block;
  width: 26px;
  height: 26px;
  background: url("img/sprite-v6.png") no-repeat -116px 0px;
  background: url("img/sprite-v6.svg") no-repeat -116px 0px, none;
  text-indent: -99999px;
  cursor: pointer; }

.arrow {
  display: inline-block;
  width: 10px;
  height: 16px;
  background: url("img/sprite-v6.png") no-repeat -144px 0px;
  background: url("img/sprite-v6.svg") no-repeat -144px 0px, none;
  text-indent: -99999px;
  margin: 0 0 0 0.25em; }

.scrollUp {
  display: none; }
  @media (min-width: 480px) {
    .scrollUp {
      display: inline-block;
      text-decoration: none;
      text-align: center;
      position: absolute;
      top: -1.25vw;
      width: auto;
      left: 50%;
      transform: translateX(-50%);
      cursor: pointer;
      z-index: 500; } }
  .scrollUp::after {
    display: block;
    margin: 0 auto;
    content: "";
    width: 52px;
    height: 20px;
    background: url("img/sprite-v6.png") no-repeat -207px 0px;
    background: url("img/sprite-v6.svg") no-repeat -207px 0px, none;
    cursor: pointer; }

.scrollDown {
  display: none; }
  @media (min-width: 480px) {
    .scrollDown {
      display: inline-block;
      text-decoration: none;
      text-align: center;
      position: absolute;
      bottom: -1.25vw;
      width: auto;
      left: 50%;
      transform: translateX(-50%);
      cursor: pointer;
      z-index: 500; } }
  .scrollDown::after {
    display: block;
    margin: 1.25vw auto 0;
    content: "";
    width: 52px;
    height: 20px;
    background: url("img/sprite-v6.png") no-repeat -155px 0px;
    background: url("img/sprite-v6.svg") no-repeat -155px 0px, none;
    cursor: pointer; }
