// Variables
//

// Breakpoint-Sass library import + breakpoint config
@import "./node_modules/breakpoint-sass/stylesheets/_breakpoint.scss";
$phone-landscape: 480px;
$tablet-portrait: 768px;
$tablet-landscape: 992px;
$laptop: 1280px (min-height 700px);
$monitor: 1440px (min-height 700px);

// Colors
$primary: #90433b;

// hotSpots
$outer: #efe3cf;
$inner: #dbaa35;

// $primary-lgt3: lighten($primary, 30%);
// $primary-lgt2: lighten($primary, 20%);
// $primary-lgt1: lighten($primary, 10%);

// $primary-drk1: darken($primary, 10%);
// $primary-drk2: darken($primary, 20%);
// $primary-drk3: darken($primary, 30%);

// Neutrals
$black: #000;
$white: #fff;

$gray-dark: #4d4d4d;
$gray: #666;
$gray-lgt: #ededed;

// $gray-lgt5: lighten($gray, 50%);
// $gray-lgt4: lighten($gray, 40%);
// $gray-lgt3: lighten($gray, 30%);
// $gray-lgt2: lighten($gray, 20%);
// $gray-lgt1: lighten($gray, 10%);

// $gray-drk1: darken($gray, 10%);
// $gray-drk2: darken($gray, 20%);
// $gray-drk3: darken($gray, 30%);

// Font Families

/**
 * @license
 * MyFonts Webfont Build ID 3614215, 2018-07-23T16:39:39-0400
 * 
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are 
 * explicitly restricted from using the Licensed Webfonts(s).
 * 
 * You may obtain a valid license at the URLs below.
 * 
 * Webfont: FrutigerLTPro-Light by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/frutiger/frutiger-pro-45-light/
 * 
 * Webfont: FrutigerLTPro-LightCn by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/frutiger/pro-47-light-condensed/
 * 
 * 
 * License: https://www.myfonts.com/viewlicense?type=web&buildid=3614215
 * Licensed pageviews: 15
 * Webfonts copyright: Copyright &#x00A9; 2014 - 2016 Monotype Imaging Inc. All rights reserved.
 * 
 * © 2018 MyFonts Inc
*/

@font-face {
  font-family: "FrutigerLTPro-Light";
  src: url("webfonts/372607_0_0.eot");
  src: url("webfonts/372607_0_0.eot?#iefix") format("embedded-opentype"),
    url("webfonts/372607_0_0.woff2") format("woff2"),
    url("webfonts/372607_0_0.woff") format("woff"),
    url("webfonts/372607_0_0.ttf") format("truetype");
}

@font-face {
  font-family: "FrutigerLTPro-LightCn";
  src: url("webfonts/372607_1_0.eot");
  src: url("webfonts/372607_1_0.eot?#iefix") format("embedded-opentype"),
    url("webfonts/372607_1_0.woff2") format("woff2"),
    url("webfonts/372607_1_0.woff") format("woff"),
    url("webfonts/372607_1_0.ttf") format("truetype");
}

$type-primary: "FrutigerLTPro-Light", sans-serif;
$type-secondary: "FrutigerLTPro-LightCn", sans-serif;
